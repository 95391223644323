import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <div className="container text-base h-96 overflow-hidden max-w-6xl">
          <h1 className="font-bold text-5xl border-b border-white pb-5 mb-10">Contact</h1>
          <p className="my-5">Let's work together! Let's work together!</p>
          <p className="my-5"><a href="linkto:connect@flatblackism.com">connect@flatblackism.com</a></p>
          <p className="my-5">315 N Puente St, Suite D, Brea, CA 92821</p>
          <p className="my-5"><a href="https://instagram.com/flatblack.ism" target="_blank" className="flex gap-3 items-center justify-start"><i className="bx bxl-instagram text-xl text-white inline-block" /><span className="inline-block">@flatblack.ism</span></a></p>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="About" />

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
